<template>
  <div v-if="!isFullScreenError">
    <h2>{{ title }}</h2>
    <slot name="description"></slot>
  </div>
  <section v-else-if="isFullScreenError" class="tw-h-screen">
    <slot name="title">
      {{ title }}
    </slot>
    <slot name="description"></slot>
  </section>
</template>

<script lang="ts">
import { NotFoundComponentMixin } from '~/mixins/notFoundComponent.mixin';

export default defineNuxtComponent({
  name: 'Theme4NotFound',

  mixins: [NotFoundComponentMixin],
});
</script>
